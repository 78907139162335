import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import p1 from '../images/p1.jpg';
import p2 from '../images/p2.jpg';
import p3 from '../images/p3.jpg';
import p4 from '../images/p4.jpg';
import p7 from '../images/p7.jpg';
import p8 from '../images/p8.jpg';
import p9 from '../images/p9.jpg';
import p10 from '../images/p10.jpg';

const products = [
  {
    id: 1,
    name: 'Stainless Steel Pressure Vessel',
    href: '#',
    imageSrc: p1,
    imageAlt: 'product',
  },
  {
    id: 2,
    name: 'Stainless Steel Air Receiver Surge Tank',
    href: '#',
    imageSrc: p2,
    imageAlt: 'product',
  },
  {
    id: 3,
    name: 'Mild Steel Heavy Vessel Tank',
    href: '#',
    imageSrc: p3,
    imageAlt: 'product',
  },
  {
    id: 4,
    name: 'Mild Steel Chemicals Storage Tank',
    href: '#',
    imageSrc: p4,
    imageAlt: 'product',
  },
];

const products2 = [
  {
    id: 1,
    name: '2000 L Mild Steel Pressure Vessel',
    href: '#',
    imageSrc: p7,
    imageAlt: 'product',
   },
   {
    id: 1,
    name: 'Stainless Steel Fabrication Services',
    href: '#',
    imageSrc: p8,
    imageAlt: 'product',
   },
   {
    id: 1,
    name: 'Mild Steel Portable Hydrogen Gas Generator Plant',
    href: '#',
    imageSrc: p9,
    imageAlt: 'product.',
   },
   {
    id: 1,
    name: 'Mild Steel Water Softener Plant ',
    href: '#',
    imageSrc: p10,
    imageAlt: 'product.',
   },
 
]

const AllProducts = () => {
  const settings1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
   <>
 <div className='bg-stone-200'>
      <div className='px-2'>
        <h1 className='text-4xl font-bold text-gray-500 text-center py-6 md:py-12'>
          Products & Services
        </h1>
        <Slider {...settings1}>
          {products.map((product) => (
            <div key={product.id} className="px-2">
              <div className='relative'>
                <div className='relative w-auto h-48 md:h-72 rounded-lg overflow-hidden'>
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className='w-full h-full'
                  />
                </div>
                <div className='relative mt-2 md:mt-4'>
                  <h3 className='text-sm font-medium text-gray-900'>
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>

    <div className='bg-stone-200'>
      <div className='px-2'>
        <h1 className='text-4xl font-bold text-gray-500 text-center py-6 md:py-12'>
          Products & Services
        </h1>
        <Slider {...settings2}>
          {products2.map((product) => (
            <div key={product.id} className="px-2">
              <div className='relative'>
                <div className='relative w-auto h-48 md:h-72 rounded-lg overflow-hidden'>
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className='w-full h-full'
                  />
                </div>
                <div className='relative mt-2 md:mt-4'>
                  <h3 className='text-sm font-medium text-gray-900'>
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
   
   
   </>

    
  );
};

export default AllProducts;

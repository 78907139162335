import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import logobg from "../images/logo1.png";

export default function Example() {
  return (
    <Disclosure as="nav" className={`bg-gradient-to-r from-zinc-100 from-75% to-blue-900 to-5% shadow sticky top-0 z-50 px-2`}>
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-16">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center text-base text-gray-600">
                  <Link to="/" className="">
                  
                    <img
                            src={logobg}
                            alt="logo"
                            className="w-96 h-auto max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl"
                          />
                  </Link>
                </div>
                {/* Responsive menu links */}
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 sm:flex-1 justify-end">
                  <Link
                    to="/"
                    className="border-transparent text-blue-900 hover:border-gray-500 hover:text-pink-700 inline-flex items-center pr-12 px-1 pt-1 border-b-2 text-base font-semibold"
                  >
                    Home
                  </Link>
                  <Link
                    to="/products"
                    className="border-transparent text-white hover:border-gray-500 hover:text-pink-700 inline-flex items-center px-1 pt-1 border-b-2 text-base font-semibold"
                  >
                    Product
                  </Link>
                  <Link
                    to="/contact"
                    className="border-transparent text-white hover:border-gray-500 hover:text-pink-700 inline-flex items-center px-1 pt-1 border-b-2 text-base font-semibold"
                  >
                    Contact US
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1">
              <Link
                to="/"
                className="border-transparent text-blue-900 hover:border-gray-500 hover:text-pink-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold"
              >
                Home
              </Link>
              <Link
                to="/products"
                className="border-transparent text-blue-900 hover:border-gray-500 hover:text-pink-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold "
              >
                Product
              </Link>
              <Link
                to="/contact"
                className="border-transparent text-blue-900 hover:border-gray-500 hover:text-pink-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold"
              >
                Contact US
              </Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

import React from 'react'
import '../App.css'

import l1 from '../images/l1.png';
import l2 from '../images/nobg.png';
import ansal from '../images/ansalbg.png';
import l4 from '../images/grebg.png';



const carouselImages = [
  {
    imageURL: l1,
  },
  {
    imageURL: l2,

  },
  {
    imageURL: ansal,
  },
  {
    imageURL:l4,
  },
  
];
const projects = [
  { name: 'Ansal Api Megapolis'},
  { name: 'Ansal Api Aquapolis' },
  { name: 'Mahaluxmi Realtech Pvt Ltd' },
  { name: 'SBL Group' },
  { name: 'India Engineering And Fabrication' },
  { name: 'Sustainable Glazing Pvt Ltd' },
   { name: 'Flowmoto Engineering' },
   { name: 'Mostyn Futech Corporation' },
  { name: 'Star Facilities Pvt Ltd' },
  { name: 'Ankur Traders And Manufactures' },
  { name: 'Goel Equipments Pvt. Ltd.' },
  { name: 'Hindustan Engineering Ltd.' },
  { name: 'Spectrum Engineering Tech Pvt. Ltd.' },
  // { name: 'U.P.R.N.N' },
  { name: 'Eros Hotels and Resorts Pvt. Ltd.' },
  { name: 'Sjp Hotels & Resorts Pvt. Ltd.' },
  { name: 'We3 Infraprojects Pvt. Ltd.' },
  // { name: 'Many More..........' },

]




const ourClient = () => {
  return (
   <>
     
     <section className='px-2 py-10' style={{backgroundColor:"#e6ebe9"}}>
  <div className="container px-4">
    <h2 className="text-4xl font-bold text-gray-500 text-center py-12">OUR PRESTIGIOUS CLIENTS</h2>
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 py-2 lg:px-56">
      {carouselImages.map((projects, index) => (
        <div
          className={`bg-${index % 2 === 0 ? "zinc" : "slate"}-300 shadow-2xl shadow-gray-500 h-32 rounded-lg col-span-1 flex justify-center items-center px-2`}
        >
          <img src={projects.imageURL} alt="Company XYZ" />
        </div>
      ))}
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 justify lg:px-56">
      {projects.map((project, index) => (
        <div
          className={`bg-${index % 2 === 0 ? "zinc" : "slate"}-300 shadow-2xl shadow-gray-500 h-32 rounded-lg col-span-1 flex justify-center paragraph items-center lg:text-[25px] sm:text-[21px] text-black font-family pl-4 px-2`}
        >
          {project.name}
        </div>
      ))}
    </div>
  </div>
</section>

   
   
   </>
  )
}

export default ourClient
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css'
import c1 from '../images/c4.png';
import c2 from '../images/c22.png';
import c3 from '../images/c11.png';
import c4 from '../images/c33.png';
import c5 from  '../images/c5.png';
import c6 from  '../images/c6.png';
import c7 from '../images/c7.png';
import s21 from '../images/s21.jpg';
import s22 from '../images/s22.jpg';
import s23 from '../images/s23.jpg';

const carouselImages = [
  {
    imageURL: c6,
  },
  {
    imageURL: c7,
  },

  {
    imageURL: c1,
  },
  {
    imageURL: c2,
  },
  {
    imageURL: c3,
  },
  {
    imageURL: c4,
  },
  {
    imageURL: s21,
  },
  {
    imageURL: s22,
  },
  {
    imageURL: s23,
  },

  {
    imageURL: c5,
  },
 
];
function SliderComponent() {
  const settings = {
    dots:true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
    {carouselImages.map((item, index) => (
      <div key={index} className="">
          <img className="slick-slide"
          src={item.imageURL}
          alt="img slider"
          style={{ width: "100%" }}
        />
        </div>
    ))}
  </Slider>
  );
}

export default SliderComponent;

import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a1 from '../images/a1.jpg';
import a2 from '../images/a2.jpg';
import a3 from '../images/a3.png';
import a4 from '../images/a4.jpg';
import a5 from '../images/a5.jpg';
import p9 from '../images/p9.jpg';
import p10 from '../images/p10.jpg';
import infra1 from "../images/infra1.jpeg";
import infra2 from "../images/infra2.jpeg";
import fire from "../images/fire.jpg";
import corner from "../images/corner.jpg";



const carouselImages = [
  {
    imageURL: a1,
  },
  {
    imageURL: a2,
  },
  {
    imageURL: fire,
  },
  {
    imageURL: corner,
  },
 
  {
    imageURL: infra2,
  },
  {
    imageURL: a3,
  },
  {
    imageURL: a4,
  },
  {
    imageURL: a5,
  },

  {
    imageURL: infra1,
  },
  {
    imageURL: p9,
  },
  {
    imageURL: p10,
  },

];

const About = () => {
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="bg-zinc-100 px-2">
        <div className="container mx-auto">
          <h1 className="text-xl font-bold tracking-tight text-gray-600 sm:text-5xl lg:text-6xl pt-8 ml-7">
            ABOUT US
          </h1>
        </div>
        <div className="container px-4 py-3 mx-auto flex flex-col items-center justify-center lg:flex-row">
          <div className="lg:w-1/2 sm:w-2/3 lg:pr-10 px-4 text-justify">
            <p className="text-lg text-gray-600">
              R.R.Infrastructures is an Indian Based manufacturer and trader of
              stainless steel Railings and fittings. Established in 2010,
              R.R.Infrastructures is also a leading manufacture and service
              provider of hydrogen gas generator plants, sewage treatment plants,
              industrial pressure vessel and many more. R.R Infrastructures has
              a full-fledged product range and a team that aims to be one of
              India's prominent manufacturers and suppliers for various sectors.
              Our Manufacturing unit is located at Greater Noida, in the state
              of Uttar Pradesh, India.
            </p>
            <p className="mt-1 text-lg text-gray-600">
              We are also  manufacturer of stainless steel hot water storage tanks, pressure
              vessels, steel material handling equipments, trollies, steel water
              sinks, and mechanical equipment like Flash mixer, Floculators with
              complete Automation, Screw conveyors. We also do complete fire fighting works. 
  </p>
  <p className="text-xl text-gray-500">A complete solution and Consultation for Engineering products.</p>
          </div>
          <div className="lg:w-1/2 sm:w-2/3 w-full ml-4 overflow-hidden sm:mt-0">
            <Slider {...settings}>
              {carouselImages.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.imageURL}
                    alt={`H${index + 1}`}
                    // style={{ width: "100%", maxWidth: "500px" }}
                    className='w-full max-h-96'
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import p11 from '../images/p11.png';
import p12 from '../images/p12.png';
import p13 from '../images/p13.png';
import p14 from '../images/p14.png';
import p15 from '../images/p15.png';
import p17 from '../images/p17.png';
import p18 from '../images/p18.png';
import p21 from '../images/p21.png';
import q1 from '../images/q1.png';
import q2 from '../images/q2.png';
import q3 from '../images/q3.png';
import q4 from '../images/q4.png';
import w1 from  '../images/w1.png';
import w2 from '../images/w2.png';
import w3 from '../images/w3.png';
import w4 from '../images/w4.png';
import w5 from '../images/w5.png';
import w6 from '../images/w6.png';
import w7 from '../images/w7.png';
import w8 from '../images/w8.png';
import w9 from '../images/w9.png';
import w10 from '../images/w10.png';
import w11 from '../images/w11.png';
import w12 from '../images/w12.png';
import f1 from '../images/f1.png';
import f2 from '../images/f2.png';
import f3 from '../images/f3.png';
import f4 from '../images/f4.png';


const products1 = [
  {
    id: 1,
    imageSrc: p11,
    imageAlt: 'product',
  },
  {
    id: 2,
    imageSrc: p12,
    imageAlt: 'product',
  },
  {
    id: 3,
    imageSrc: p13,
    imageAlt: 'product.',
  },
  {
    id: 4,
    imageSrc: p14,
    imageAlt: 'product.',
  },
  {
    id: 5,

    imageSrc: p15,
    imageAlt: 'product',
   },
   {
    id: 6,
    imageSrc: p21,
    imageAlt: 'product.',
   },
   {
    id: 7,

    imageSrc: p17,
    imageAlt: 'product.',
   },
   {
    id: 8,
   
   imageSrc: p18,
    imageAlt: 'product.',
   },

];


const products2 = [
  {
    id: 1,
   imageSrc: q1,
    imageAlt: 'product',
   },
   {
    id: 2,
    imageSrc: q2,
    imageAlt: 'product',
   },
   {
    id: 3,
    imageSrc: q3,
    imageAlt: 'product.',
   },
   {
    id: 4,
    imageSrc: q4,
    imageAlt: 'product.',
   },
   
   {
    id: 7,
   imageSrc: f1,
   imageAlt: 'product',
   },
   {
    id: 6,
   imageSrc: f2,
   imageAlt: 'product',
   },
   {
    id: 5,
   imageSrc: f4,
   imageAlt: 'product',
   },
   {
    id: 8,
   imageSrc: f3,
   imageAlt: 'product',
   },


 
]

const products3 = [
  {
    id: 1,
   imageSrc: w1,
   imageAlt: 'product',
   },
 
   {
    id: 2,
   imageSrc: w2,
   imageAlt: 'product',
   },
 
   {
    id: 3,
   imageSrc: w3,
   imageAlt: 'product',
   },
   
   {
    id: 4,
   imageSrc: w4,
   imageAlt: 'product',
   },
   
   {
    id: 5,
   imageSrc: w5,
   imageAlt: 'product',
   },
   {
    id: 6,
   imageSrc: w6,
   imageAlt: 'product',
   },
   {
    id: 7,
   imageSrc: w7,
   imageAlt: 'product',
   },
   {
    id: 8,
   imageSrc: w8,
   imageAlt: 'product',
   },
   {
    id: 9,
   imageSrc: w9,
   imageAlt: 'product',
   },
   {
    id: 10,
   imageSrc: w10,
   imageAlt: 'product',
   },
   {
    id: 11,
   imageSrc: w11,
   imageAlt: 'product',
   },
   {
    id: 12,
   imageSrc: w12,
   imageAlt: 'product',
   },
  


 
]

const Galary = () => {
  const settings1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings3 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-zinc-100 px-2">
      <div className="px-1">
        <h1 className="text-4xl font-bold text-gray-500 text-center py-12">
          Our Products
        </h1>
      </div>
      <div className=" bg-zinc-100 px-2" >
      <div className="px-1">
  <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-4xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">STAINLESS STEEL</span> RAILING BALUSTERS</h1>
      <div className="mt-8">
   <Slider {...settings3} className="gap-2">
          {products3.map((product) => (
            <div key={product.id} className='px-1'>
              <div className="relative">
                <div className="relative w-full h-80 rounded-lg overflow-hidden">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-full h-full object-center "
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-sm font-medium text-gray-900 py-6">
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>
      </div>
      <div className=" bg-zinc-100 px-2" >
      <div className="px-1">
  <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-4xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">STAINLESS STEEL</span> RAILING BALUSTERS</h1>
      <div className="mt-8">
   <Slider {...settings1} className="gap-2">
          {products1.map((product) => (
            <div key={product.id} className='px-1'>
              <div className="relative">
                <div className="relative w-full h-80 rounded-lg overflow-hidden">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-full h-full object-center "
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-sm font-medium text-gray-900 py-6">
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>
      </div>
     
      <div className=" bg-zinc-100 px-2" >
      <div className="px-1">
  <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-4xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">STAINLESS STEEL</span> RAILING BALUSTERS AND FITTING</h1>
      <div className="mt-8">
   <Slider {...settings2} className="gap-2">
          {products2.map((product) => (
            <div key={product.id} className='px-1'>
              <div className="relative">
                <div className="relative w-full h-80 rounded-lg overflow-hidden">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="w-full h-full object-center "
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-sm font-medium text-gray-900 py-6">
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>
      </div>
    </div>
  );
};

export default Galary;

import React from 'react'
import Client from '../Pages/Client'
import About from '../Pages/About'
import Galary from '../Pages/Galary'
import Testimonial from '../Pages/Testimonial'
import Slider from '../Pages/slider'
import Borderpart from './borderpart'

const MainPage = () => {
  return (
<>
<Borderpart/>
<Slider/>
<About/>
<Client/>
<Galary/>
<Testimonial/>

</>
  )
}

export default MainPage
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import MainPage from "./components/MainPage";
import Navbar from "./components/Navbar";
import AllProducts from "./Pages/AllProducts";
import Contact from "./Pages/Contact";
import ErrorPage from "./Pages/ErrorPage";
import { HashRouter as Router } from 'react-router-dom';


function App() {
  return (
  <>
  <Router>
  <Navbar/>
  <Routes>
 <Route path="/" exact element={<MainPage />} />
 <Route path="/products" exact element={<AllProducts />} />
 <Route path="/contact" exact element={<Contact />} />
 <Route path="*" exact element={<ErrorPage />} />
 </Routes>
  <Footer/>
  </Router>


 

  </>
  );
}

export default App;

import React from 'react'



const borderpart = () => {
  return (
 <>
 <div className='h-3 bg-gradient-to-r from-gray-300 from-10% via-zinc-800 via-40% to-cyan-800 to-90% '>

 </div>
 
 </>
  )
}

export default borderpart
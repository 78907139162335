import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css'

const people = [
  {
    name: 'Director SEPTL',
    title: 'RR infrastructure is a good manufacturing unit with nice quality and efficiency..having skilled manpower and in Time Delivery..',
  },
  {
    name: 'Director Ankur Traders',
    title: 'Better quality work with in time.I wish them a better future ahead.',
  },
  {
    name: 'Anand Singh',
    title: `I am extremely pleased with RR Infrastructure Company. From the moment we engaged with them, they have consistently exceeded our expectations. Their team's professionalism, attention to detail, and commitment to delivering quality results are truly remarkable.`,
  },
  {
    name: 'Ansal',
    title: 'The realities of today has its consequences, hence the need is real. I am glad RRInfrastructures is helping out businesses towards a bright tomorrow..',
  },
  {
    name: 'Hari ',
    title: ' Many companies offer scores of different services. RRInfrastructures offers positivity to businesses. We are glad to have these kind of wellness services at our premises. People really loved it..',
  },
  {
    name: 'Vivek',
    title: ' RRInfrastructures has perhaps changed my perspective on manufacturering of stainless steel hot water storage tanks..',
  },
  
];

const Testimonial = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (

    <section className="tstm__section  px-2" style={{backgroundColor:"#e6ebe9"}} >
      <div className="container px-4">
        <h4 className="text-4xl font-bold text-gray-500 text-center py-12">Our Customer Feedback </h4>
        <div>
          <Slider {...settings}>
            {people.map((people, index) => (
              <div key={index}>
                <div className="tstm__card__container  ">
                  <div className="tstm__card h-40 rounded-lg bg-cyan-500">
                  <h4 className="tstm__card__title text-white  font-medium truncate ">{people.name}</h4>
                    <p className="tstm__card__para text-white leading-relaxed mb-6 " title={people.title}>
                      {people.title}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
    
  );
};

export default Testimonial;
